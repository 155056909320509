// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { AptosWalletError } from '@aptos-labs/wallet-standard';
import { SerializedWalletRequest } from '../WalletRequest';
import { SerializedWalletResponse } from '../WalletResponse';
import { isTypedMessage, TypedMessage } from './common';

export class AptosWalletRequestMessage implements TypedMessage {
  static readonly TYPE = 'AptosWalletRequest';
  readonly __messageType = AptosWalletRequestMessage.TYPE;

  constructor(
    public nonce: string,
    public serializedValue: SerializedWalletRequest,
  ) {}
}

export class AptosWalletSuccessResponseMessage implements TypedMessage {
  static readonly TYPE = 'AptosWalletSuccessResponseMessage';
  readonly __messageType = AptosWalletSuccessResponseMessage.TYPE;

  constructor(
    public serializedValue: SerializedWalletResponse,
    public nonce: string,
  ) {}
}

export class AptosWalletErrorResponseMessage implements TypedMessage {
  static readonly TYPE = 'AptosWalletErrorResponseMessage';
  readonly __messageType = AptosWalletErrorResponseMessage.TYPE;

  constructor(
    public error: AptosWalletError,
    public nonce: string,
  ) {}
}

export type AptosWalletResponseMessage = AptosWalletSuccessResponseMessage | AptosWalletErrorResponseMessage;

export function isAptosWalletResponseMessage(message: any): message is AptosWalletResponseMessage {
  return (
    isTypedMessage(AptosWalletSuccessResponseMessage, message) ||
    isTypedMessage(AptosWalletErrorResponseMessage, message)
  );
}
