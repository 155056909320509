// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Deserializer, InputGenerateTransactionPayloadData, MoveFunctionId } from '@aptos-labs/ts-sdk';
import { BCS, Types } from 'aptos';

export type SerializableV1 = { serialize(serializer: BCS.Serializer): void };
export type DeserializableV2Class<T> = { deserialize(deserializer: Deserializer): T };

export function convertV1toV2<TV1 extends SerializableV1, TV2>(src: TV1, dst: DeserializableV2Class<TV2>) {
  const serializedBytes = BCS.bcsToBytes(src);
  const deserializerV2 = new Deserializer(serializedBytes);
  return dst.deserialize(deserializerV2);
}

export function convertPayloadInputFromV1ToV2(inputV1: Types.TransactionPayload): InputGenerateTransactionPayloadData {
  if ('multisig_address' in inputV1) {
    throw new Error('Multisig payload not supported');
  }

  if ('code' in inputV1) {
    throw new Error('Script payload not supported');
  }

  if ('function' in inputV1) {
    return {
      function: inputV1.function as MoveFunctionId,
      functionArguments: inputV1.arguments,
      typeArguments: inputV1.type_arguments,
    };
  }

  throw new Error('Unsupported payload type');
}
