// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { registerWallet } from '@aptos-labs/wallet-standard';
import { AptosConnectWallet, AptosConnectWalletConfig } from './AptosConnectWallet';

export * from './AptosConnectAccount';
export * from './AptosConnectWallet';
export * from './AptosConnectWalletPlugin';

export function registerAptosConnect(config: AptosConnectWalletConfig = {}) {
  const walletPlugin = new AptosConnectWallet(config);
  registerWallet(walletPlugin);
}
