// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { AccountInfo as ACAccountInfo } from '@aptos-connect/wallet-api';
import {
  AccountAddress,
  AnyRawTransaction,
  Aptos,
  AptosConfig,
  Network,
  NetworkToNodeAPI,
  PendingTransactionResponse,
} from '@aptos-labs/ts-sdk';
import {
  AccountInfo,
  APTOS_CHAINS,
  AptosConnectNamespace,
  AptosDisconnectNamespace,
  AptosFeatures,
  AptosGetAccountNamespace,
  AptosGetNetworkNamespace,
  AptosOnAccountChangeNamespace,
  AptosOnNetworkChangeNamespace,
  AptosSignAndSubmitTransactionFeature,
  AptosSignAndSubmitTransactionInput,
  AptosSignAndSubmitTransactionNamespace,
  AptosSignAndSubmitTransactionOutput,
  AptosSignMessageInput,
  AptosSignMessageNamespace,
  AptosSignMessageOutput,
  AptosSignTransactionNamespace,
  AptosSignTransactionOutput,
  AptosWallet,
  AptosWalletError,
  AptosWalletErrorCode,
  NetworkInfo,
  UserResponse,
  UserResponseStatus,
} from '@aptos-labs/wallet-standard';
import { deserializePublicKeyB64, serializePublicKeyB64 } from '@identity-connect/crypto';
import { ACDappClient, ACDappClientConfig } from '@identity-connect/dapp-sdk';
import { AptosConnectAccount } from './AptosConnectAccount';
import { walletIcon, walletName, walletUrl } from './shared';

interface SerializedCurrentAccount {
  address: string;
  publicKey: string;
}

function customAccountToStandardAccount({ address, name, publicKey }: ACAccountInfo) {
  return new AccountInfo({
    address,
    ansName: name,
    publicKey,
  });
}

export interface AptosConnectWalletConfig extends Omit<ACDappClientConfig, 'defaultNetworkName'> {
  network?: Network;
}

export class AptosConnectWallet implements AptosWallet {
  // region connectedAccount

  private static connectedAccountStorageKey = '@aptos-connect/connectedAccount';

  private static get connectedAccount(): AccountInfo | undefined {
    const serialized = localStorage.getItem(AptosConnectWallet.connectedAccountStorageKey);
    if (!serialized) {
      return undefined;
    }

    try {
      const { address, publicKey } = JSON.parse(serialized) as SerializedCurrentAccount;
      return serialized
        ? new AccountInfo({
          address: AccountAddress.from(address),
          publicKey: deserializePublicKeyB64(publicKey),
        })
        : undefined;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Failed to parse state, resetting it');
      this.connectedAccount = undefined;
      return undefined;
    }
  }

  private static set connectedAccount(value: AccountInfo | undefined) {
    if (value !== undefined) {
      const serialized: SerializedCurrentAccount = {
        address: value.address.toString(),
        publicKey: serializePublicKeyB64(value.publicKey),
      };
      localStorage.setItem(AptosConnectWallet.connectedAccountStorageKey, JSON.stringify(serialized));
    } else {
      localStorage.removeItem(AptosConnectWallet.connectedAccountStorageKey);
    }
  }

  // endregion

  // region AptosWallet

  readonly name = walletName;
  readonly version = '1.0.0';

  readonly icon = walletIcon;
  readonly url = walletUrl;

  readonly chains = APTOS_CHAINS;

  // eslint-disable-next-line class-methods-use-this
  get accounts() {
    const { connectedAccount } = AptosConnectWallet;
    return connectedAccount ? [new AptosConnectAccount(connectedAccount)] : [];
  }

  get features(): AptosFeatures & AptosSignAndSubmitTransactionFeature {
    return {
      [AptosConnectNamespace]: {
        connect: this.connect.bind(this),
        version: '1.0.0',
      },
      [AptosDisconnectNamespace]: {
        disconnect: this.disconnect.bind(this),
        version: '1.0.0',
      },
      [AptosGetAccountNamespace]: {
        account: this.getAccount.bind(this),
        version: '1.0.0',
      },
      [AptosGetNetworkNamespace]: {
        network: this.getNetwork.bind(this),
        version: '1.0.0',
      },
      [AptosOnAccountChangeNamespace]: {
        onAccountChange: this.onAccountChange.bind(this),
        version: '1.0.0',
      },
      [AptosOnNetworkChangeNamespace]: {
        onNetworkChange: this.onNetworkChange.bind(this),
        version: '1.0.0',
      },
      [AptosSignAndSubmitTransactionNamespace]: {
        signAndSubmitTransaction: this.signAndSubmitTransaction.bind(this),
        version: '1.1.0',
      },
      [AptosSignMessageNamespace]: {
        signMessage: this.signMessage.bind(this),
        version: '1.0.0',
      },
      [AptosSignTransactionNamespace]: {
        signTransaction: this.signTransaction.bind(this),
        version: '1.0.0',
      },
    };
  }

  // endregion

  // PetraWallet

  private readonly aptosClient: Aptos;

  private readonly client: ACDappClient;

  constructor({ network = Network.MAINNET, ...clientConfig }: AptosConnectWalletConfig) {
    this.client = new ACDappClient(clientConfig);

    if (!NetworkToNodeAPI[network]) {
      throw new Error('Network not supported');
    }

    const aptosConfig = new AptosConfig({ network });
    this.aptosClient = new Aptos(aptosConfig);
  }

  async connect(): Promise<UserResponse<AccountInfo>> {
    // If this is an auto-connect, try not opening the prompt
    const { connectedAccount } = AptosConnectWallet;
    if (connectedAccount !== undefined) {
      return { args: connectedAccount, status: UserResponseStatus.APPROVED };
    }

    const response = await this.client.connect();
    if (response.status === 'dismissed') {
      return { status: UserResponseStatus.REJECTED };
    }

    const newConnectedAccount = customAccountToStandardAccount(response.args.account);
    AptosConnectWallet.connectedAccount = newConnectedAccount;

    return {
      args: newConnectedAccount,
      status: UserResponseStatus.APPROVED,
    };
  }

  async disconnect() {
    const { connectedAccount } = AptosConnectWallet;
    if (connectedAccount) {
      await this.client.disconnect(connectedAccount.address);
      AptosConnectWallet.connectedAccount = undefined;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getAccount(): Promise<AccountInfo> {
    const { connectedAccount } = AptosConnectWallet;
    if (!connectedAccount) {
      // TODO: this function should fail gracefully
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }
    return customAccountToStandardAccount(connectedAccount);
  }

  async getNetwork(): Promise<NetworkInfo> {
    const { network } = this.aptosClient.config;
    const chainId = await this.aptosClient.getChainId();
    const url = NetworkToNodeAPI[network];
    return {
      chainId,
      name: network,
      url,
    };
  }

  async signMessage(input: AptosSignMessageInput): Promise<UserResponse<AptosSignMessageOutput>> {
    const { connectedAccount } = AptosConnectWallet;
    if (!connectedAccount) {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }

    const { chainId } = await this.getNetwork();
    const { message, nonce } = input;

    const encoder = new TextEncoder();
    const messageBytes = encoder.encode(message);
    const nonceBytes = encoder.encode(nonce);

    const response = await this.client.signMessage({
      chainId,
      message: messageBytes,
      nonce: nonceBytes,
      signerAddress: connectedAccount.address,
    });

    if (response.status === 'dismissed') {
      return { status: UserResponseStatus.REJECTED };
    }

    const { fullMessage, signature } = response.args;

    const extraResponseArgs = {
      address: connectedAccount.address.toString(),
      application: '',
      chainId,
      message,
      nonce,
      prefix: 'APTOS' as const,
    };

    return {
      args: {
        fullMessage,
        signature,
        ...extraResponseArgs,
      },
      status: UserResponseStatus.APPROVED,
    };
  }

  async signTransaction(
    transaction: AnyRawTransaction,
    _asFeePayer?: boolean,
  ): Promise<UserResponse<AptosSignTransactionOutput>> {
    const { connectedAccount } = AptosConnectWallet;
    if (!connectedAccount) {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }

    const feePayer = transaction.feePayerAddress ? { address: transaction.feePayerAddress } : undefined;
    const secondarySigners = transaction.secondarySignerAddresses?.map((address) => ({ address }));
    const response = await this.client.signTransaction({
      feePayer,
      secondarySigners,
      signerAddress: connectedAccount.address,
      transaction: transaction.rawTransaction,
    });

    if (response.status === 'dismissed') {
      return { status: UserResponseStatus.REJECTED };
    }

    return {
      args: response.args.authenticator,
      status: UserResponseStatus.APPROVED,
    };
  }

  async signAndSubmitTransaction(
    args: AptosSignAndSubmitTransactionInput,
  ): Promise<UserResponse<AptosSignAndSubmitTransactionOutput>> {
    const { gasUnitPrice, maxGasAmount, payload } = args;

    const { connectedAccount } = AptosConnectWallet;
    if (!connectedAccount) {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }

    const response = await this.client.signAndSubmitTransaction({
      gasUnitPrice,
      maxGasAmount,
      network: this.aptosClient.config.network,
      payload,
      signerAddress: connectedAccount.address,
    });

    if (response.status === 'dismissed') {
      return { status: UserResponseStatus.REJECTED };
    }

    // We should change the return type to only the hash. No point in returning the whole transaction object
    const txnResponse = (await this.aptosClient.getTransactionByHash({
      transactionHash: response.args.txnHash,
    })) as PendingTransactionResponse;

    return {
      args: txnResponse,
      status: UserResponseStatus.APPROVED,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  async onAccountChange(_callback?: (newAccount: AccountInfo) => void): Promise<void> {
    // TODO
  }

  // eslint-disable-next-line class-methods-use-this
  async onNetworkChange(_callback?: (newNetwork: NetworkInfo) => void): Promise<void> {
    // Not applicable
  }

  // endregion
}
