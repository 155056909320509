// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { AccountInfo as ACAccountInfo, SignAndSubmitTransactionRequest, UserResponse } from '@aptos-connect/wallet-api';
import {
  AccountAddress,
  AccountAuthenticator,
  AccountAuthenticatorEd25519,
  AccountAuthenticatorSingleKey,
  Aptos,
  AptosConfig,
  Ed25519PublicKey,
  Ed25519Signature,
  InputGenerateTransactionPayloadData,
  MultiEd25519PublicKey,
  MultiKey,
  Network,
  NetworkToNodeAPI,
  SignedTransaction,
  TransactionAuthenticatorEd25519,
  TransactionAuthenticatorSingleSender,
  TransactionPayload,
} from '@aptos-labs/ts-sdk';
import {
  AccountInfo,
  AdapterPlugin,
  AnyRawTransaction,
  InputTransactionData,
  NetworkInfo,
  SignMessagePayload,
  SignMessageResponse,
  WalletName,
} from '@aptos-labs/wallet-adapter-core';
import { AptosWalletError, AptosWalletErrorCode } from '@aptos-labs/wallet-standard';
import { ACDappClient, ACDappClientConfig } from '@identity-connect/dapp-sdk';
import { TxnBuilderTypes, Types } from 'aptos';
import { convertPayloadInputFromV1ToV2, convertV1toV2 } from './conversion';
import { walletIcon, walletName, walletUrl } from './shared';

function customAccountToStandardAccount({ address, name, publicKey }: ACAccountInfo): AccountInfo {
  if (publicKey instanceof MultiEd25519PublicKey || publicKey instanceof MultiKey) {
    throw new Error('Unsupported public key type');
  }

  return {
    address: address.toString(),
    ansName: name,
    publicKey: publicKey.toString(),
  };
}

function accountAuthenticatorToTransactionAuthenticator(accountAuthenticator: AccountAuthenticator) {
  if (accountAuthenticator instanceof AccountAuthenticatorEd25519) {
    return new TransactionAuthenticatorEd25519(accountAuthenticator.public_key, accountAuthenticator.signature);
  }
  if (accountAuthenticator instanceof AccountAuthenticatorSingleKey) {
    if (accountAuthenticator.isEd25519()) {
      return new TransactionAuthenticatorEd25519(
        accountAuthenticator.public_key.publicKey as Ed25519PublicKey,
        accountAuthenticator.signature.signature as Ed25519Signature,
      );
    }
    return new TransactionAuthenticatorSingleSender(accountAuthenticator);
  }
  throw new Error('Cannot convert account authenticator to a compatible transaction authenticator');
}

function unwrapUserResponse<TArgs>(response: UserResponse<TArgs>) {
  if (response.status === 'dismissed') {
    throw new AptosWalletError(0, 'Rejected');
  }
  return response.args;
}

// Redefining interface, to include some arguments that production dapps are using
export interface TransactionOptions {
  expirationSecondsFromNow?: number;
  expirationTimestamp?: number;
  gasUnitPrice?: number;
  gas_unit_price?: number;
  maxGasAmount?: number;
  max_gas_amount?: number;
  sender?: string;
  sequenceNumber?: number;
}

export interface AptosConnectWalletPluginConfig extends Omit<ACDappClientConfig, 'defaultNetworkName'> {
  network?: Network;
}

export class AptosConnectWalletPlugin implements AdapterPlugin {
  // Hack to make this always available
  readonly providerName = 'open';
  readonly provider: typeof window.open | undefined;
  readonly version = 'v2';

  readonly name = walletName as WalletName;
  readonly url = walletUrl;
  readonly icon = walletIcon;

  readonly client: ACDappClient;
  readonly aptosClient: Aptos;

  constructor({ network = Network.MAINNET, ...clientConfig }: AptosConnectWalletPluginConfig) {
    this.client = new ACDappClient(clientConfig);

    if (!NetworkToNodeAPI[network]) {
      throw new Error('Network not supported');
    }

    const aptosConfig = new AptosConfig({ network });
    this.aptosClient = new Aptos(aptosConfig);
  }

  // region connectedAccount
  private static connectedAccountStorageKey = 'AptosConnectWalletPlugin.connectedAccount';

  private static get connectedAccount(): AccountInfo | undefined {
    const value = localStorage.getItem(AptosConnectWalletPlugin.connectedAccountStorageKey);
    return value ? JSON.parse(value) : undefined;
  }

  private static set connectedAccount(value: AccountInfo | undefined) {
    if (value !== undefined) {
      localStorage.setItem(AptosConnectWalletPlugin.connectedAccountStorageKey, JSON.stringify(value));
    } else {
      localStorage.removeItem(AptosConnectWalletPlugin.connectedAccountStorageKey);
    }
  }

  // endregion

  async connect(): Promise<AccountInfo> {
    // If this is an auto-connect, try not opening the prompt
    const { connectedAccount } = AptosConnectWalletPlugin;
    if (connectedAccount !== undefined) {
      return connectedAccount;
    }

    const response = await this.client.connect();
    if (response.status === 'dismissed') {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }

    const newConnectedAccount = customAccountToStandardAccount(response.args.account);
    AptosConnectWalletPlugin.connectedAccount = newConnectedAccount;
    return newConnectedAccount;
  }

  async account(): Promise<AccountInfo> {
    const [firstAccount] = await this.client.getConnectedAccounts();
    if (firstAccount === undefined) {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }
    return customAccountToStandardAccount(firstAccount);
  }

  async disconnect(): Promise<void> {
    const { connectedAccount } = AptosConnectWalletPlugin;
    if (connectedAccount) {
      await this.client.disconnect(connectedAccount.address);
      AptosConnectWalletPlugin.connectedAccount = undefined;
    }
  }

  async signAndSubmitTransaction(
    payloadV1InputOrGenerateTxnInput: Types.TransactionPayload | InputTransactionData,
    optionsV1?: TransactionOptions,
  ): Promise<{ hash: Types.HexEncodedBytes }> {
    const { connectedAccount } = AptosConnectWalletPlugin;
    if (!connectedAccount) {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }

    let payload: TransactionPayload | InputGenerateTransactionPayloadData;
    let options: Omit<SignAndSubmitTransactionRequest.Args, 'payload'>;

    if ('data' in payloadV1InputOrGenerateTxnInput) {
      const { data: payloadV2Input, options: optionsV2 } = payloadV1InputOrGenerateTxnInput;
      payload = payloadV2Input;
      options = {
        expirationTimestamp: optionsV2?.expireTimestamp,
        gasUnitPrice: optionsV2?.gasUnitPrice,
        maxGasAmount: optionsV2?.maxGasAmount,
        network: this.aptosClient.config.network,
      };
    } else {
      payload = convertPayloadInputFromV1ToV2(payloadV1InputOrGenerateTxnInput);
      options = {
        expirationTimestamp: optionsV1?.expirationTimestamp,
        gasUnitPrice: optionsV1?.gasUnitPrice ?? optionsV1?.gas_unit_price,
        maxGasAmount: optionsV1?.maxGasAmount ?? optionsV1?.max_gas_amount,
        network: this.aptosClient.config.network,
      };
    }

    const response = await this.client.signAndSubmitTransaction({
      signerAddress: AccountAddress.from(connectedAccount.address),
      ...options,
      payload,
    });
    const { txnHash } = unwrapUserResponse(response);
    return { hash: txnHash };
  }

  async signAndSubmitBCSTransaction(
    payloadV1: TxnBuilderTypes.TransactionPayload,
    options?: any,
  ): Promise<{ hash: Types.HexEncodedBytes }> {
    const { connectedAccount } = AptosConnectWalletPlugin;
    if (!connectedAccount) {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }
    const payload = convertV1toV2(payloadV1, TransactionPayload);
    const response = await this.client.signAndSubmitTransaction({
      expirationTimestamp: options?.expirationTimestamp,
      gasUnitPrice: options?.gasUnitPrice ?? options?.gas_unit_price,
      maxGasAmount: options?.maxGasAmount ?? options?.max_gas_amount,
      network: this.aptosClient.config.network,
      payload,
      signerAddress: AccountAddress.from(connectedAccount.address),
    });
    const { txnHash } = unwrapUserResponse(response);
    return { hash: txnHash };
  }

  async signMessage(args: SignMessagePayload): Promise<SignMessageResponse> {
    const { connectedAccount } = AptosConnectWalletPlugin;
    if (!connectedAccount) {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }
    const chainId = await this.aptosClient.getChainId();
    const { message, nonce } = args;

    const encoder = new TextEncoder();
    const messageBytes = encoder.encode(message);
    const nonceBytes = encoder.encode(nonce);

    const response = await this.client.signMessage({
      chainId,
      message: messageBytes,
      nonce: nonceBytes,
      signerAddress: AccountAddress.from(connectedAccount.address),
    });

    const { fullMessage, signature } = unwrapUserResponse(response);

    const extraResponseArgs = {
      address: connectedAccount.address.toString(),
      application: this.client.dappInfo.domain,
      chainId,
      message,
      nonce,
      prefix: 'APTOS' as const,
    };

    return {
      fullMessage,
      signature: signature.toString(),
      ...extraResponseArgs,
    };
  }

  async signTransaction(
    transactionOrPayload: Types.TransactionPayload | TxnBuilderTypes.TransactionPayload | AnyRawTransaction,
    optionsOrAsFeePayer?: TransactionOptions | boolean,
  ): Promise<AccountAuthenticator | Uint8Array> {
    const { connectedAccount } = AptosConnectWalletPlugin;
    if (!connectedAccount) {
      throw new AptosWalletError(AptosWalletErrorCode.Unauthorized);
    }

    // If "rawTransaction" is part of the args, then we have a v2 request
    if ('rawTransaction' in transactionOrPayload) {
      const transaction = transactionOrPayload;
      const feePayer = transaction.feePayerAddress ? { address: transaction.feePayerAddress } : undefined;
      const secondarySigners = transaction.secondarySignerAddresses?.map((address) => ({ address }));
      const response = await this.client.signTransaction({
        feePayer,
        secondarySigners,
        signerAddress: AccountAddress.from(connectedAccount.address),
        transaction: transaction.rawTransaction,
      });
      const { authenticator } = unwrapUserResponse(response);
      return authenticator;
    }

    if (!(transactionOrPayload instanceof TxnBuilderTypes.TransactionPayload)) {
      throw new Error('Not supported');
    }

    const payload = convertV1toV2(transactionOrPayload, TransactionPayload);
    const options = optionsOrAsFeePayer as TransactionOptions | undefined;

    const sender = options?.sender
      ? {
          address: AccountAddress.from(options.sender),
        }
      : undefined;

    const response = await this.client.signTransaction({
      expirationSecondsFromNow: options?.expirationSecondsFromNow,
      expirationTimestamp: options?.expirationTimestamp,
      gasUnitPrice: options?.gasUnitPrice ?? options?.gas_unit_price,
      maxGasAmount: options?.maxGasAmount ?? options?.max_gas_amount,
      network: this.aptosClient.config.network,
      payload,
      sender,
      sequenceNumber: options?.sequenceNumber,
      signerAddress: AccountAddress.from(connectedAccount.address),
    });

    const { authenticator, rawTransaction } = unwrapUserResponse(response);

    if (rawTransaction === undefined) {
      throw new Error('The wallet did not return a raw transaction');
    }

    const txnAuthenticator = accountAuthenticatorToTransactionAuthenticator(authenticator);
    const signedTransaction = new SignedTransaction(rawTransaction, txnAuthenticator);
    return signedTransaction.bcsToBytes();
  }

  // eslint-disable-next-line class-methods-use-this
  async onNetworkChange(_callback?: (args: NetworkInfo) => void): Promise<void> {
    // Not applicable
  }

  // eslint-disable-next-line class-methods-use-this
  async onAccountChange(_callback?: (args: AccountInfo) => void): Promise<void> {
    // Not applicable
  }

  async network(): Promise<NetworkInfo> {
    const { network } = this.aptosClient.config;
    const chainId = await this.aptosClient.getChainId();
    const url = NetworkToNodeAPI[network];
    return {
      chainId: chainId.toString(),
      name: network,
      url,
    };
  }
}
